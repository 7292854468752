.markdown-preview tr {
    background-color: transparent !important;
}
.markdown-preview ol {
    list-style-type: auto;
    padding-left: 1em;
  }

.custom-markdown {
    color: black !important;
}

.custom-markdown-white {
    color: white !important;
}

.custom-markdown pre {
    color: #c9d1d9;
}